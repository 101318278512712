import React, { useEffect } from 'react';

import Layout from '../components/layout'
import PerformanceOne from '../components/performance/PerformanceOne'
import PerformanceTwo from '../components/performance/PerformanceTwo'
import PerformanceThree from '../components/performance/PerformanceThree'
import PerformanceFour from '../components/performance/PerformanceFour'
import PerformanceFive from '../components/performance/PerformanceFive'
import PerformanceIntro from '../components/performance/PerformanceIntro'
import PerformanceSix from '../components/audit/AuditCta'

const Performance = props => {
    useEffect(() => {
        window.scrollTo(0, 0);
        
        // Find all elements with the specified class
        const ctaElements = document.querySelectorAll('.audit-anchor-link'); // Replace with your actual class name
    
        // Add click event listener to each element
        ctaElements.forEach(element => {
          element.addEventListener('click', handleCTAClick);
        });
    
        // Define the click event handler
        function handleCTAClick(event) {
          event.preventDefault();
    
          const meetingsContainer = document.querySelector('.meetings-iframe-container'); // Get the meetings container
          if (meetingsContainer) {
            meetingsContainer.classList.add('visible'); // Show the meetings container
    
            // Scroll to the meetings container after a short delay
            setTimeout(() => {
              meetingsContainer.scrollIntoView({ behavior: 'smooth' });
            }, 100);
    
            // Add the script to create the iframe
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
            script.async = true;
            meetingsContainer.appendChild(script);
          }
        }
    
        // Cleanup: Remove event listeners when the component unmounts
        return () => {
          ctaElements.forEach(element => {
            element.removeEventListener('click', handleCTAClick);
          });
        };
      }, []);

      return (
    <Layout
      lang='pt'
      title="Torne o seu site WordPress mais rápido!"
      meta={{
        description: 'Descubra como otimizar o desempenho e a velocidade do seu site WordPress com a ajuda da log.',
      }}
      rootProps={{ className: 'page work audit-page bg-blue' }}
      headerProps={{ isBlue: true }}
    >
      <PerformanceOne lang="pt" />
      <PerformanceIntro lang="pt" />
      <PerformanceTwo lang="pt" />
      <PerformanceFour lang="pt" />
      <PerformanceThree lang="pt" />
      <PerformanceSix lang="pt" />
      <PerformanceFive lang="pt" />
    </Layout>
  );
};

export default Performance
