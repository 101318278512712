import React, { useEffect, useState } from 'react';
import TextGroup, { CTA } from '../Texts'

import audit2 from '../../assets/images/audit/audit-2.png'
import ServiceModal from "../ServiceModal";
import close_icon from '../../assets/images/close.svg'

const PerformanceTwo = ({ lang }) => {
  const isEn = lang === 'en';
  const [clickedTileIndexes, setClickedTileIndexes] = useState([]);

  const [modalData, setModalData] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const handleTileClick = (index) => {

    setClickedTileIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((id) => id !== index);
      } else {
        return [...prevIndexes, index];
      }
    });

    //show modal
    setModalVisible(true);
    // set modal data on click
    setModalData(sliderTiles[index]);
  };
    

   // hide modal on close.
   const onModalCloseHandler = () => {
    setModalVisible(false);
    setClickedTileIndexes([]);
  };
    

  const sliderTiles = [
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? 'Velocidade de Carregamento' : 'Velocidade de Carregamento',
      },
      modal: {
        title:"Velocidade de Carregamento",
        description: "Otimizamos a velocidade do seu website para uma experiência ágil, mantendo os visitantes envolvidos e satisfeitos.",
      }
    },
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? 'Otimização de Imagens' : 'Otimização de Imagens',
      },
      modal: {
        title:"Otimização de Imagens",
        description: "Aprimoramos imagens para carregamentos rápidos, elevando a parte visual, sem comprometer o desempenho.",
      }
    },
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? 'Cache' : 'Cache',
      },
      modal: {
        title:"Cache",
        description: "Implementamos cache para acelerar o acesso, garantindo uma experiência de navegação rápida e eficiente.",
      }
    },
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? 'Minificação de Código' : 'Minificação de Código',
      },
      modal: {
        title:"Minificação de Código",
        description: "Reduzimos o tamanho do código do seu website, garantindo uma execução mais eficiente e uma resposta mais ágil.",
      }
    },
    {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Redução de Solicitações HTTP' : 'Redução de Solicitações HTTP',
        },
        modal: {
          title:"Redução de Solicitações HTTP",
          description: "Minimizamos as solicitações para acelerar carregamentos, otimizando a eficiência e a resposta do seu website.",
        }
       
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'CDN (Rede de Entrega de Conteúdo)' : 'CDN (Rede de Entrega de Conteúdo)',
        },
        modal: {
          title:"CDN (Rede de Entrega de Conteúdo)",
          description: "Expandimos globalmente com uma CDN, garantindo um acesso rápido a conteúdos em qualquer local.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Alojamento e Recursos do Servidor' : 'Alojamento e Recursos do Servidor',
        },
        modal: {
          title:"Alojamento e Recursos do Servidor",
          description: "Oferecemos um alojamento sólido e recursos avançados, garantindo um desempenho excepcional do seu website WordPress.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Plugins' : 'Plugins',
        },
       
        modal: {
          title:"Plugins",
          description: "Selecionamos e otimizamos plugins para melhorar funcionalidades, sem sacrificar a velocidade do website.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Temas' : 'Temas',
        },
       
        modal: {
          title:"Temas",
          description: "Escolhemos e otimizamos temas para um design atrativo, sem comprometer a velocidade de carregamento.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Base de Dados' : 'Base de Dados',
        },
       
        modal: {
          title:"Base de Dados",
          description: "Otimizamos bases de dados para operações eficientes, assegurando uma performance ágil do seu website.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Mobile-Friendly' : 'Mobile-Friendly',
        },
       
        modal: {
          title:"Mobile-Friendly",
          description: "Adaptamos o seu website para dispositivos móveis, proporcionando uma experiência veloz e acessível em qualquer ecrã.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Segurança do website' : 'Segurança do website',
        },
       
        modal: {
          title:"Segurança do website",
          description: "Garantimos uma performance segura, protegendo o seu website contra ameaças e assegurando a confiança dos visitantes.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Correções e Ações' : 'Correções e Ações',
        },
       
        modal: {
          title:"Correções e Ações",
          description: "Agimos proativamente, mantendo a performance no topo e respondendo rapidamente a possíveis melhorias.",
        }
      },
  ]

  return (
    <section id="services-two" className="bg-white services lp-tiles audit-two">
      <div className="content container">
        <div className="col-12 slider-wrapper">
          <TextGroup
            rootProps={{
              className: 'col-12 heading-large',
            }}
            title={{
              type: 'h2',
              text: isEn
                ? 'We work with you as one team.'
                : 'Serviços log performance:',
            }}
          />
          <div className="audit2-cnt">
            <div className="row tiles-row row-relative">
              {sliderTiles.map((tile, i) => (
                <div
                  key={`services-column-${i}`}
                  className={`col-4 col-12-medium tile-column ${
                    clickedTileIndexes.includes(i) ? 'clicked' : ''
                  }`}
                  onClick={() => handleTileClick(i)}
                >
                  <TextGroup {...tile} />
                </div>
              ))}
            </div>
            <div className="cta cta-audit">
              <CTA
                rootProps={{
                  className: 'button primary audit-anchor-link gethelp-two',
                  to: isEn ? '#' : '#',
                }}
                text={isEn ? "EN" : 'Obtenha a nossa ajuda'}
              />
            </div>
          </div>
        </div>
      </div>

      <ServiceModal
        visible={modalVisible}
        title={modalData?.modal?.title}
        description={modalData?.modal?.description}
      >
        <img 
          src={close_icon} 
          alt="Close" 
          className="service-modal__close"
          onClick={onModalCloseHandler}
        />
          <a 
            href="#" 
            className="button audit-btn audit-anchor-link gethelp-box"
            onClick={onModalCloseHandler}
          >
           {isEn ? "EN" : 'Obtenha a nossa ajuda'}
          </a>
        </ServiceModal>
    </section>
  )
}

export default PerformanceTwo
