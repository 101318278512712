import React from 'react'
import _ from 'lodash'

import introimg from '../../assets/images/audit/intro/performance-intro.png'

import TextGroup, { Image } from '../Texts'

const PerformanceIntro = ({ lang }) => {
  const isEn = lang === 'en'

  return (
    <section id="performance-intro" className="bg-white bg-gray lp-intro">
      <div className="content container">
        <div className="row">
            <div className="tile row">
                <div className="col-6 col-12-medium image image-margin-right">
                <Image image={introimg} loading="lazy" />
                </div>
                <div className="text-group col-6 col-12-medium">
                    <h2>O Poder da Performance</h2>
                    <p>Na log, compreendemos que a velocidade de carregamento, a eficiência e a capacidade de resposta são cruciais para proporcionar a melhor experiência possível aos visitantes.</p>
                    <ul>
                        <li>Aceleramos o seu website, otimizando imagens, cache, base de dados, de forma a proporcionar uma experiência de utilizador de excelência.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}
export default PerformanceIntro
